import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '邻里帖子',
    value: 1
  },
  {
    text: '项目活动',
    value: 5
  },
  {
    text: '小区投票',
    value: 3
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)
// 评论类型
const messageType = {
  1: '邻里帖子',
  3: '小区投票',
  5: '项目活动'
}

export {
  statusMap,
  setStatusOps,
  messageType
}
